import React, { useContext, createContext, useState, useEffect } from 'react';
import $ from 'jquery';
import PaymentForm from '../components/PaymentForm';
import GroupStatusForm from '../../GroupStatusForm';

const PaymentClientContext = createContext();

const usePaymentClient = () => {
  const context = useContext(PaymentClientContext);

  if (!context) {
    throw new Error(
      'usePaymentClient solo puede ser llamado dentro de PaymentClientProvider'
    );
  }

  return context;
};

const PaymentClientProvider = ({ children }) => {
  const initialState = {
    refresh: false,
    requisition: null,
    showModal: false,
  };

  const [hasRefresh, setHasRefresh] = useState(initialState.refresh);
  const [requisition, setRequisitionToPayment] = useState(
    initialState.requisition
  );

  const [showModal, setShowModal] = useState(initialState.showModal);

  const showPaymentDialog = () => {
    $('#modal-payment-client').modal('show');
    setShowModal(true);
  };

  const closePaymentDialog = () => {
    $('#modal-payment-client').modal('hide');
  };

  const updatedStatus = () => {
    setHasRefresh(true);
  };

  useEffect(() => {
    $('#modal-payment-client').on('hide.bs.modal', () => {
      setShowModal(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const values = {
    showPaymentDialog,
    closePaymentDialog,
    setRequisitionToPayment,
    setHasRefresh,
    hasRefresh,
    requisition,
  };

  return (
    <PaymentClientContext.Provider value={values}>
      {children}

      <div
        className="modal fade"
        id="modal-payment-client"
        tabIndex="-1"
        aria-labelledby="modal-payment-clientLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header align-items-center">
              <h5 className="modal-title" id="modal-payment-client-label">
                Registrar pago
              </h5>
              <button
                type="button"
                className="btn btn-link text-body"
                onClick={() => closePaymentDialog()}
              >
                <i className="fas fa-times"></i>
              </button>
            </div>
            <div className="modal-body">{showModal && <PaymentForm />}</div>
          </div>
        </div>
      </div>

      <GroupStatusForm
        requisition={requisition}
        updatedStatus={updatedStatus}
      />
    </PaymentClientContext.Provider>
  );
};

export { usePaymentClient };

export default PaymentClientProvider;
